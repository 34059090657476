import { Card, Image, Text, Group, Badge, Button, ActionIcon, BackgroundImage, Rating, NumberInput, useMantineTheme } from '@mantine/core';
import classes from './../../styles/ProductCard.module.css';
import { Link } from 'react-router-dom';
import { useLocalStorage, useMediaQuery } from '@mantine/hooks';
import useStore from '../../store/useStore';
import { css } from '@linaria/core';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IconMinus, IconPlus, IconShoppingCart } from '@tabler/icons-react';


interface Props {
  id: string;
  title: string;
  image: string;
  price: number;
  priceAfterDiscount: number;
  rating?: number;
}

const cardStyles = (color: string) => css`
  cursor: pointer;

  &:hover {
    background-color: red;
  }
`;

export function ProductCard({ id, image, title, price, priceAfterDiscount, rating }: Props) {
    const theme = useMantineTheme();
    const matches = useMediaQuery('(max-width: 36em)');
    const defaultValue: any = [];
    const dataStore = useStore((state: any) => state.store);
    const setItemToCart = useStore((state: any) => state.setCarts);
    const [quantity, setQuantity] = useState(1)

    const addItemToLocalStorage = () => {
        setItemToCart({ id, image, title, price, priceAfterDiscount, rating }, quantity)
        setQuantity(1)
    }

    return (
        <Card withBorder radius="md" p="md" className={classes.card}>
            <Card.Section style={{position: 'relative'}} className={classes.firstSection}  component={Link} to={`/product/${id}`}>
                {/* <Image src={image} alt={title} w={"100%"} h={"100%"} pos={'absolute'} fit={'fill'} /> */}
                <LazyLoadImage
                    src={`${process.env.REACT_APP_API_URL_IMAGES}/${image}.webp`}
                    alt={""}
                    height={"100%"}
                    width={"100%"}
                    delayTime={0}
                    style={{position: "absolute", objectFit: "fill"}}
                    placeholderSrc={`${process.env.REACT_APP_API_URL_THUMBNAILS}/${image}.webp`}
                />
                {priceAfterDiscount > 0
                    ? <Badge variant="filled" color={"red"} c={dataStore?.information?.textColor || "#fff"}  size='lg' className={classes.badge} radius={0}>
                        تخفيض
                    </Badge>
                    : null
                }
            </Card.Section>

            <Card.Section className={classes.section} mt="md"  component={Link} to={`/product/${id}`}>
                <Text fz={matches ? "md" : "lg"} fw={500} component={Link} to={`/product/${id}`} className={classes.link}>
                    {title}
                </Text>
                {rating && rating > 0
                    ? <Rating value={rating} fractions={2} readOnly />
                    : null
                }
            </Card.Section>
        
            <Card.Section className={classes.section}  component={Link} to={`/product/${id}`}>
                <Group gap={10}>
                    <Text size={matches ? "sm" : "md"} fw={'bold'} className={classes.label} c="red">
                        {priceAfterDiscount > 0 ? priceAfterDiscount : price} د.ج
                    </Text>
                    {priceAfterDiscount > 0 
                        ? <Text size={matches ? "xs" : "sm"} fw={'bold'} className={classes.label} c="dark" td="line-through">
                            {price} د.ج
                            </Text>
                        : null
                    }
                </Group>
            </Card.Section>

            <Group w={"100%"} flex={"auto"} mt={"xs"} align='flex-end'>
                <NumberInput
                    min={1} size='sm'
                    value={quantity}
                    flex={1}
                    styles={{
                        input: {
                            border: `1px solid ${theme.colors.gray[4]}`,
                            color: theme.colors.dark[3],
                            fontWeight: 600,
                            paddingInlineStart: 36,
                            paddingInlineEnd: 36,
                            textAlign: 'center',
                            width: "100%"
                        }
                    }}

                    onChange={(value: number | string) => {
                        if (typeof value === "number") {
                            setQuantity(value)
                        }
                    }}
                    rightSection ={
                        <ActionIcon
                            variant="transparent" h={"100%"} w={36} radius={0} 
                            style={{borderRight: `1px solid ${theme.colors.gray[4]}`}}
                            onClick={() => setQuantity(quantity - 1)}
                        >
                            <IconMinus size={16} color={theme.colors.dark[2]} stroke={2} />
                        </ActionIcon>
                    }
                    rightSectionWidth={36}
                    leftSection={
                        <ActionIcon 
                            variant="transparent" h={"100%"} w={36} radius={0} 
                            style={{borderLeft: `1px solid ${theme.colors.gray[4]}`}}
                            onClick={() => setQuantity(quantity + 1)}
                        >
                            <IconPlus size={16} color={theme.colors.dark[2]} stroke={2} />
                        </ActionIcon>
                    }
                    leftSectionWidth={36}
                />
            </Group>
        
            <Group flex={"auto"} mt={"xs"} align='flex-end'>
                <Button 
                    variant='outline' radius="md" style={{ flex: 1 }} color={dataStore?.information?.backgroundColor || "#d84a2f"}
                    c={dataStore?.information?.backgroundColor || "#d84a2f"} leftSection={<IconShoppingCart size={20} stroke={1.5} />}
                    onClick={addItemToLocalStorage}
                >
                    إضافة للسلة
                </Button>
                {/* <Button 
                    variant='filled' radius="md" style={{ flex: 1 }} color={dataStore?.information?.backgroundColor || "#d84a2f"}
                    component={Link} to={`/product/${id}`} c={dataStore?.information?.textColor || "#fff"}
                    
                    // className={classes.button}
                >
                    شراء
                </Button> */}
            </Group>
        </Card>
    );
}