import { Accordion, Box, Button, Card, Container, Grid, Group, Image, Stack, Text, TextInput, useMantineTheme, } from '@mantine/core';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import axios from 'axios';
import useStore from '../store/useStore';
import { CartProductCard } from '../components/Cards';
import { useForm } from '@mantine/form';
import Wilayas from './../helper/wilayas.json';
import Communes from './../helper/communes.json';
import { readLocalStorageValue, useLocalStorage } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { IconShoppingCartFilled } from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';

export function Cart () {
    const theme = useMantineTheme();
    const navigate = useNavigate();
    
    const {onSubmit, reset, values, getInputProps, errors, setFieldValue, setValues} = useForm({
        initialValues: {
            name: '', phone: ""
        },
        validate: {
            name: (value) => (value?.length < 2 ? 'الاسم غير صالح' : null),
            phone: (value: string) => (/^(05|06|07)[0-9]{8}$/.test(value) ? null : 'رقم الهاتف غير صالح'),
        },
        validateInputOnBlur: true,
        validateInputOnChange: true
    });

    const updateCartQuantity = useStore((state: any) => state.updateCartQuantity);
    const carts = useStore((state: any) => state.getCarts());
    const deleteCartItem = useStore((state: any) => state.deleteCartItem);
    const clearCarts = useStore((state: any) => state.clearCarts);

    const [price, setPrice] = useState(0);
    const [facebookApis, setFacebookApis] = useState<any[]>([]);
    const dataStore = useStore((state: any) => state.store);
    let [myIP, setMyIP] = useState<string>("");

    useEffect(() => {
        axios.get(`https://api.ipify.org/?format=json`)
        .then(({data}) => {
            setMyIP(data.ip);
        })
        .catch((error) => console.log(error))

        axios.get(`${process.env.REACT_APP_API_URL}/pixels`)
        .then(({data}) => {
            const filterApis = data.filter((item: any) => item?.name?.toLowerCase() === "Api Conversion".toLowerCase())
            setFacebookApis(filterApis)
        })
        .catch((error) => console.log(error));
    }, [])

    useEffect(() => {
        if (carts && carts.length) {
            const newPrice = carts?.reduce((accumulator: number, item: any) => {
                return accumulator + ((item?.product?.priceAfterDiscount > 0 ? item?.product?.priceAfterDiscount : item?.product?.price) * item?.quantity)
            }, 0)
            
            setPrice(newPrice)
        }
    }, [carts])

    const onSubmitForm = ({name, phone}: any) => {
        const allProducts = []
        for (let i = 0; i < carts.length; i++) {
            const item = carts[i];
            allProducts.push({
                idProduct: item?.product?.id,
                price: item?.product?.priceAfterDiscount > 0 ? item?.product?.priceAfterDiscount : item?.product?.price,
                quantity: item?.quantity
            })
        }

        axios.post(`${process.env.REACT_APP_API_URL}/orders`, {
            "fullName": name,
            "phone": phone,
            "ipAddress": myIP,
            "price": price,

            products: allProducts
        })
        .then(({data}) => {
            navigate(`/order/confirmed`)
            onReset()
        })
        .catch((error) => {
            console.log(error)
            navigate(`/order/failed`)
            // setTealAlert(false)
            // setRedAlert(true)
        });
    }

    const deleteItemFromLocalStorage = (index: any) => {
        // const row = carts?.[index]
        deleteCartItem(index)
    }
    
    const changeQuantityItemFromLocalStorage = (index: any, valueAdd: number) => {
        // const row = carts?.[index]
        updateCartQuantity(index, valueAdd);
    }
    
    const onReset = () => {
        reset()
        setPrice(0)
        clearCarts()
    }

    return (
        <>
            <HeaderSimple />
            <Container size={'lg'} mt={130}>
                <Grid gutter="xl" mt={50}>
                    {carts?.length > 0 ?
                        <>
                            <Grid.Col span={{ base: 12, sm: 12, md: 5, lg: 5, xl: 5 }}>
                                {carts?.map((item: any, index: number) => (
                                    <CartProductCard
                                        key={index} index={index}
                                        title={item?.product?.title}
                                        image={item?.product?.image}
                                        price={item?.product?.priceAfterDiscount > 0 ? item?.product?.priceAfterDiscount : item?.product?.price}
                                        priceAfterDiscount={item?.product?.priceAfterDiscount}
                                        quantity={item?.quantity}
                                        hasOffer={item?.product?.hasOffer || false}
                                        changeQuantityItemFromLocalStorage={changeQuantityItemFromLocalStorage}
                                        deleteItemFromLocalStorage={deleteItemFromLocalStorage}
                                    />
                                ))}
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, sm: 12, md: 7, lg: 7, xl: 7 }}>
                                <Card dir='rtl' withBorder radius="md" p={30} style={{border: `2px solid ${dataStore?.information?.backgroundColor || "#d84a2f"}`}}>
                                    <Text ta={'center'} fw={700} >أضف معلوماتك في الأسفل لإكمال الطلب</Text>

                                    <form onSubmit={onSubmit(onSubmitForm)}>
                                        <Grid mt={30}>
                                            <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                                                <TextInput
                                                    placeholder="الاسم الكامل"
                                                    size='lg'
                                                    styles={{
                                                        input: {
                                                            border: `2px solid ${dataStore?.information?.backgroundColor || "#d84a2f"}7d`
                                                        },
                                                    }}
                                                    {...getInputProps('name')}
                                                />
                                            </Grid.Col>
                                            <Grid.Col span={{ base: 12, sm: 12, md: 6 }}>
                                                <TextInput
                                                    placeholder="رقم الهاتف"
                                                    size='lg'
                                                    styles={{
                                                        input: {
                                                            border: `2px solid ${dataStore?.information?.backgroundColor || "#d84a2f"}7d`
                                                        },
                                                    }}
                                                    {...getInputProps('phone')}
                                                />
                                            </Grid.Col>
                                            
                                            <Grid.Col span={{ base: 12, sm: 12, md: 12 }}>
                                                <Button
                                                    type='submit' fullWidth variant="filled"
                                                    size='lg' color={dataStore?.information?.backgroundColor || "#d84a2f"}
                                                    disabled={Object.keys(errors)?.length > 0}
                                                    c={dataStore?.information?.textColor || "#fff"}
                                                >انقر هنا لتأكيد الطلب</Button>
                                            </Grid.Col>
                                        </Grid>
                                    </form>

                                    <Accordion
                                        mt={20} dir='rtl' variant="filled" defaultValue="1"
                                        style={{background: theme.colors.gray[0]}}
                                        styles={{
                                            label: {
                                                fontWeight: 700,
                                                color: theme.colors.gray[7]
                                            },
                                            icon: {
                                                color: theme.colors.gray[5]
                                            },
                                            content: {
                                                padding: 0
                                            }
                                        }}
                                    >
                                        <Accordion.Item value="1" style={{background: theme.colors.gray[0]}}>
                                            <Accordion.Control style={{borderBottom: `2px solid ${dataStore?.information?.backgroundColor || "#d84a2f"}7d`}} icon={<IconShoppingCartFilled size={26} /> } >ملخص الطلب</Accordion.Control>
                                            <Accordion.Panel p={10}>
                                                <Stack gap={0}>
                                                    <Stack style={{borderBottom: `2px dotted ${dataStore?.information?.backgroundColor || "#d84a2f"}7d`}} py={10}>
                                                        {carts?.map((item: any, index: number) => (
                                                            <Group justify='space-between' py={0} key={index}>
                                                                <Text flex={3} fw={700} c={"gray.7"} >{item?.product?.title}</Text>
                                                                
                                                                <Text flex={1} fw={700} c={"gray.7"} >
                                                                    <Text span={true} size='xs' fw={700} c={theme.white} bg={dataStore?.information?.backgroundColor || "#d84a2f"} p={2} >{item?.quantity}×</Text> {" "}
                                                                    {((item?.product?.priceAfterDiscount > 0 ? item?.product?.priceAfterDiscount : item?.product?.price) * item?.quantity)?.toFixed(2)} د.ج
                                                                </Text>
                                                            </Group>
                                                        ))}
                                                    </Stack>
                                                    
                                                    <Group justify='space-between' py={10}>
                                                        <Text flex={3} fw={700} c={"gray.7"} >السعر الإجمالي</Text>
                                                        
                                                        <Box flex={1}>
                                                            <Text fw={700} c={dataStore?.information?.backgroundColor || "#d84a2f"} >
                                                                { Math.round(price)} د.ج
                                                            </Text>
                                                        </Box>
                                                    </Group>
                                                </Stack>
                                            </Accordion.Panel>
                                        </Accordion.Item>
                                    </Accordion>
                                </Card>
                            </Grid.Col>
                        </>
                        : <Grid.Col span={12}>
                            <Stack align='center' justify='center' mt={50}>
                                <Image src={"/shopping-trolley.png"} h={"100px"} w={"100px"} fit='contain' />
                                <Text size='xl' fw={600}>لا يوجد منتجات في سلتك</Text>
                                <Button 
                                    size='md' fw={700} mt={5} variant='light'
                                    color={dataStore?.information?.backgroundColor || "#d84a2f"}
                                    component={Link} to={`/`}
                                >تسوق الان</Button>
                            </Stack>
                        </Grid.Col>
                    }
                </Grid>
            </Container>
            <Footer/>
        </>
    );
}