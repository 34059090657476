import './styles/App.css';
import { Route, Routes } from 'react-router-dom';
import { Home, Product, LandingProduct, Order, Cart } from './pages';
import ReactPixel from 'react-facebook-pixel';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import useStore from './store/useStore';
import { useGeolocated } from "react-geolocated";
import { Helmet } from 'react-helmet';
import { Alert, Button, Container, Paper, TextInput, Title } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useForm } from '@mantine/form';

const cookies = new Cookies(null, { path: '/' });

function App() {
    const dataStore = useStore((state: any) => state.store);
    const {getInputProps, reset, onSubmit, setValues} = useForm({
        initialValues: {code: ""}
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean |string>(false);

    const onSubmitForm = ({code}: any) => {
        setLoading(true)
        axios.post(`${process.env.REACT_APP_API_URL}/store/check-password`, { "code": code })
        .then(({data, status, statusText}) => {
            if ("status" in data && data?.status) {
                cookies.set('code', code);
                reset()
                setLoading(false)
            } else {
                setError("كلمة مرور دخول الموقع خطأ")
                setLoading(false)
            }
        })
        .catch((error) => {
            console.log(error)
            setLoading(false)
        });
    }
    
    useEffect(() => {
    }, [cookies.get("code")])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/store`)
        .then(({data}) => {
            // cookies.set('store', JSON.stringify(dataStore));
            useStore.setState({ store: data });
        })
        .catch((error) => {
            console.log(error);
        })
    }, [])
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/pixels`)
        .then(({data}) => {
            // const facebookData = data.filter((item: any) => item.name === "facebook")
            // console.log(facebookData);
            // setFacebookToken(facebookData?.[0]?.apiKey)
            const advancedMatching = undefined;
            data?.map((item: any, index: number) => {
                if (item?.apiKey !== "" && item?.apiKey.length > 10) {
                    ReactPixel.init(item?.apiKey, advancedMatching, {
                        autoConfig: true,
                        debug: true
                    });
                }
            })
            ReactPixel.pageView();
        })
        .catch((error) => console.log(error));
    }, [])

    // useEffect(() => {
    //     if (facebookToken !== "") {
    //         ReactPixel.init(facebookToken);
    //         ReactPixel.pageView();
    //         // ReactPixel.track()
    //     }
    // }, [facebookToken])

    if (!cookies.get("code") || cookies.get("code") == "") {
        return (
            <Container size={420} h={"100vh"} display={'flex'} style={{justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                <Title ta="center" style={{fontWeight: 900}}>
                    {"دخول الموقع"}
                </Title>

                <form onSubmit={onSubmit(onSubmitForm)} >
                    <Paper withBorder shadow="md" p={30} mt={30} radius="md" miw={"30vw"}>
                        {error !== false
                            ? <Alert variant="light" color="red" title={error} icon={<IconInfoCircle />} />
                            : null
                        }
                        <TextInput
                            label={"كلمة مرور دخول الموقع"}
                            placeholder={"كلمة مرور دخول الموقع"}
                            required
                            {...getInputProps("code")} 
                        />
                        <Button loading={loading} type='submit' fullWidth mt="xl">{"إرسال"}</Button>
                    </Paper>
                </form>
            </Container>
        )
    }
    return (
        <>
            <Helmet>
                <link rel="icon" href={dataStore?.icon && dataStore?.icon !== "" ? `${process.env.REACT_APP_API_URL_IMAGES}/${dataStore?.icon}` : "/favicon.ico"} />
                <title>{dataStore?.information?.title || ""}</title>
                <meta name="description" content={dataStore?.information?.shortDescription || ""} />
            </Helmet>
            <Routes>
                <Route index path="/" element={<Home />} />
                <Route index path="/product/:id" element={<Product />} />
                <Route index path="/:id" element={<LandingProduct />} />
                <Route index path="/cart" element={<Cart />} />
                <Route index path="/order/:status/:type?" element={<Order />} />
            </Routes>
        </>
    );
}

export default App;
