/* eslint-disable @typescript-eslint/no-explicit-any */
export const createAppSlice = (set: any, get: any) => ({
    store: {},
    category: [],
    carts: JSON.parse(localStorage.getItem('bi3li-cart') || '[]'),
    getCarts: () => {
        return get().carts;
    },
    setCarts: (newCart: any, quantity: number) => {
        set((state: any) => {
            const currentCarts = state.carts;
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const itemIndex = currentCarts.findIndex((item: any) => !item.product.hasOffer && item.product.id === newCart.id);

            let updatedCarts;
            let updatedStore;
            if (!newCart.hasOffer && itemIndex !== -1) {
                // If item exists, increment the quantity
                updatedCarts = currentCarts.map((item: any, index: number) => {
                    const newQuantity = item?.quantity + quantity <= 0 ? 1 : item?.quantity + quantity
                    return index === itemIndex 
                        ? { ...item, quantity: newQuantity }
                        : item
                });
        
                updatedStore = [...updatedCarts];
        
                // Persist the updated carts to localStorage
                localStorage.setItem('bi3li-cart', JSON.stringify(updatedStore));
        
                return { carts: updatedStore };
            } else {
                // If item doesn't exist, add it to the carts
                updatedStore = [...state.carts, { quantity: quantity, product: newCart }];
        
                // Persist the updated carts to localStorage
                localStorage.setItem('bi3li-cart', JSON.stringify(updatedStore));
        
                return { carts: updatedStore };
        
            }
        });
    },
    // Function to update the quantity of an item in the cart
    updateCartQuantity: (productId: number, quantity: number) => {
        set((state: any) => {
            const updatedCarts = state.carts.map((item: any, index: number) => {
                if (index === productId) {
                    return { ...item, quantity };
                }
                return item;
            });
    
            // Persist the updated carts to localStorage
            localStorage.setItem('bi3li-cart', JSON.stringify(updatedCarts));
    
            return { carts: updatedCarts };
        });
    },
    deleteCartItem: (productId: number) => {
        set((state: any) => {
            const updatedCarts = state.carts.filter((item: any, index: number) => index !== productId);
        
            // Persist the updated carts to localStorage
            localStorage.setItem('bi3li-cart', JSON.stringify(updatedCarts));
        
            return { carts: updatedCarts };
        });
    },
    clearCarts: () => {
        set(() => {
            const clearedCarts: any[] = [];
            localStorage.setItem('bi3li-cart', JSON.stringify(clearedCarts)); // Clear carts in localStorage
            return { carts: clearedCarts };
        });
    },
  });