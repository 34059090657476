import React, { useEffect, useRef, useState } from 'react';
import { ActionIcon, Badge, Box, Button, CheckIcon, Container, Grid, Group, Image, NumberInput, Radio, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { FormOrder } from '../components/Sections';
import { Carousel } from '@mantine/carousel';
import Autoplay from 'embla-carousel-autoplay';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { HeaderSimple } from '../components/Navbar';
import { Footer } from '../components/Sections/Footer';
import { ProductCard } from '../components/Cards';
import useStore from '../store/useStore';
import { useLocalStorage, useWindowScroll } from '@mantine/hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IconMinus } from '@tabler/icons-react';
import { IconPlus } from '@tabler/icons-react';


export function Product () {
    const autoplay = useRef(Autoplay({ delay: 5000 }));
    const theme = useMantineTheme();
    let { id } = useParams();
    const [product, setProduct] = useState<any>(null)
    const [similarProducts, setSimilarProducts] = useState<any>([])
    const dataStore = useStore((state: any) => state.store);
    const [scroll, scrollTo] = useWindowScroll();
    const setItemToCart = useStore((state: any) => state.setCarts);
    const [quantity, setQuantity] = useState(1)
    const [priceAfterDiscount, setPriceAfterDiscount] = useState(0);
    const [offerSelected, setOfferSelected] = useState("none");
    
    useEffect(() => {
        scrollTo({y: 0})
        axios.get(`${process.env.REACT_APP_API_URL}/products/${id}`)
        .then(({data}) => {
            setProduct(data)
            setPriceAfterDiscount(data?.priceAfterDiscount || 0)
        })
        .catch((error) => console.log(error));
    }, [id])

    useEffect(() => {
        if (product && "upsell" in product && product.upsell) {
            axios.get(`${process.env.REACT_APP_API_URL}/upsell-products`, {
                params: {
                    categories: product.categories || [],
                    subCategories: product.subCategories || []
                }
            })
            .then(({data}) => {
                setSimilarProducts(data)
            })
            .catch((error) => console.log(error));
        }
    }, [product])
    
    useEffect(() => {
        if (offerSelected !== "none") {
            const offerFiltered = product?.offers?.filter((item: any) => item?.name === offerSelected)
            if (offerFiltered?.length > 0) {
                const offer = offerFiltered[0]
                setPriceAfterDiscount(offer?.price)
                setQuantity(offer?.quantity)
            }
        } else {
            setPriceAfterDiscount(product?.priceAfterDiscount > 0 ? product?.priceAfterDiscount : 0)
            setQuantity(1)
        }
    }, [offerSelected])


    const addItemToLocalStorage = () => {
        setItemToCart({ 
            id: product._id,
            image: product.thumbnail,
            title: product.name,
            price: product.price,
            priceAfterDiscount: priceAfterDiscount,
            hasOffer: offerSelected !== "none"
        }, quantity)
        setQuantity(1)
        setOfferSelected("none")
    }

    return (
        <>
            <HeaderSimple />
            <Container size={'lg'} mt={130}>
                <Grid gutter="xl" mt={50}>
                    <Grid.Col span={{ base: 12, sm: 12, md: 5, lg: 5, xl: 5 }}>
                        <Carousel dir='ltr' loop dragFree align="start" plugins={[autoplay.current]} onMouseEnter={autoplay.current.stop} onMouseLeave={autoplay.current.reset}>
                            <Carousel.Slide>
                                <Box pos={'relative'}>
                                    <LazyLoadImage
                                        src={`${process.env.REACT_APP_API_URL_IMAGES}/${product?.thumbnail}.webp`}
                                        alt={""}
                                        height={"100%"}
                                        width={"100%"}
                                        style={{maxHeight: "calc(100vh - 80px)", objectFit: 'cover'}}
                                        delayTime={0}
                                        placeholderSrc={`${process.env.REACT_APP_API_URL_THUMBNAILS}/${product?.thumbnail}.webp`}
                                    />
                                    {product?.priceAfterDiscount > 0
                                        ? <Badge variant="filled" color={"red"} c={dataStore?.information?.textColor || "#fff"} size='lg' radius={0} style={{position: "absolute", top: 0, left: 0, zIndex: 10}}>
                                                تخفيض
                                            </Badge>
                                        : null
                                    }
                                </Box>
                            </Carousel.Slide>
                            {product?.imagesProduct?.map((item: any, index: number) => (
                                <Carousel.Slide key={index}>
                                    <Box pos={'relative'}>
                                        <LazyLoadImage
                                            src={`${process.env.REACT_APP_API_URL_IMAGES}/${item}.webp`}
                                            alt={""}
                                            height={"100%"}
                                            width={"100%"}
                                            style={{maxHeight: "calc(100vh - 80px)", objectFit: 'cover'}}
                                            delayTime={0}
                                            placeholderSrc={`${process.env.REACT_APP_API_URL_THUMBNAILS}/${item}.webp`}
                                        />
                                        {product?.priceAfterDiscount > 0
                                            ? <Badge variant="filled" color={dataStore?.information?.backgroundColor || "#d84a2f"} size='lg' radius={0} style={{position: "absolute", top: 0, left: 0, zIndex: 10}}>
                                                    تخفيض
                                                </Badge>
                                            : null
                                        }
                                    </Box>
                                </Carousel.Slide>
                            ))}
                        </Carousel>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, sm: 12, md: 7, lg: 7, xl: 7 }}>
                        <Stack>
                            <Title order={1} fz={"28px"} fw={700}>
                                {product?.name}
                            </Title>
                            <Text fz={"18px"} c="dimmed">
                                {product?.shortDescription}
                            </Text>
                            <Group gap={10} mt={10}>
                                <Text fz={"24px"} fw={'bold'} c={dataStore?.information?.backgroundColor || "#d84a2f"}>
                                    {priceAfterDiscount > 0 ? priceAfterDiscount : product?.price} د.ج
                                </Text>
                                {priceAfterDiscount > 0
                                    ? <Text fz={"21px"} fw={'bold'} c="dimmed" td="line-through">
                                        {product?.price} د.ج
                                    </Text>
                                    : null
                                }
                                {priceAfterDiscount > 0
                                    ? <Badge variant="filled" color={'red'} size='xl' radius={"xs"}>
                                        {Math.round(100 - ((priceAfterDiscount * 100) / product?.price))}%
                                    </Badge>
                                    : null
                                }
                            </Group>

                            {product?.offers && product?.offers?.length > 0
                                ? <Grid.Col span={{ base: 12 }}>
                                    <Radio.Group name="favoriteFramework" withAsterisk value={offerSelected} onChange={setOfferSelected} >
                                        <Stack mt="xs">
                                            <Radio
                                                icon={CheckIcon} value="none" color={dataStore?.information?.backgroundColor || "#d84a2f"}
                                                label={
                                                    <Group justify='space-between'>
                                                        <Text fw={700} c={"gray.7"} >{"بدون عرض"}</Text>
                                                        <Text fw={700} c={dataStore?.information?.backgroundColor || "#d84a2f"} >{product?.price} د.ج</Text>
                                                    </Group>
                                                }
                                                styles={()=>({
                                                    root: {
                                                        background: "#fff",
                                                        border: `2px solid ${dataStore?.information?.backgroundColor || "#d84a2f"}7d`,
                                                        borderRadius: 3,
                                                        padding: "12px 10px"
                                                    },
                                                    body: { justifyContent: "flex-start" },
                                                    labelWrapper: { width: "100%" },
                                                    label: { width: "100%", cursor: 'pointer' }
                                                })}
                                            />
                                            {product?.offers.map((item: any, index: number) => (
                                                <Radio
                                                    key={index} icon={CheckIcon}
                                                    value={item?.name} color={dataStore?.information?.backgroundColor || "#d84a2f"}
                                                    label={
                                                        <Group justify='space-between'>
                                                            <Stack gap={0}>
                                                                <Text fw={700} c={"gray.7"} >{item?.name}</Text>
                                                                <Text size='sm' fw={600} c={"gray.6"} >{item?.quantity} قطعة</Text>
                                                            </Stack>
                                                            <Text fw={700} c={dataStore?.information?.backgroundColor || "#d84a2f"} >
                                                                {item?.price} د.ج
                                                            </Text>
                                                        </Group>
                                                    }
                                                    styles={()=>({
                                                        root: {
                                                            background: "#fff",
                                                            border: `2px solid ${dataStore?.information?.backgroundColor || "#d84a2f"}7d`,
                                                            borderRadius: 3,
                                                            padding: "12px 10px"
                                                        },
                                                        body: { justifyContent: "flex-start" },
                                                        labelWrapper: { width: "100%" },
                                                        label: { width: "100%", cursor: 'pointer' }
                                                    })}
                                                />
                                            ))}
                                        </Stack>
                                    </Radio.Group>
                                </Grid.Col>
                                : null
                            }

                            {offerSelected === "none"
                                ? <Group w={"100%"} flex={"auto"} mt={"xs"} align='flex-end'>
                                    <NumberInput
                                        min={1} size='sm'
                                        value={quantity}
                                        flex={1}
                                        styles={{
                                            input: {
                                                border: `1px solid ${theme.colors.gray[4]}`,
                                                color: theme.colors.dark[3],
                                                fontWeight: 600,
                                                paddingInlineStart: 36,
                                                paddingInlineEnd: 36,
                                                textAlign: 'center',
                                                width: "100%"
                                            }
                                        }}

                                        onChange={(value: number | string) => {
                                            if (typeof value === "number") {
                                                setQuantity(value)
                                            }
                                        }}
                                        rightSection ={
                                            <ActionIcon
                                                variant="transparent" h={"100%"} w={36} radius={0} 
                                                style={{borderRight: `1px solid ${theme.colors.gray[4]}`}}
                                                onClick={() => setQuantity(quantity - 1)}
                                            >
                                                <IconMinus size={16} color={theme.colors.dark[2]} stroke={2} />
                                            </ActionIcon>
                                        }
                                        rightSectionWidth={36}
                                        leftSection={
                                            <ActionIcon 
                                                variant="transparent" h={"100%"} w={36} radius={0} 
                                                style={{borderLeft: `1px solid ${theme.colors.gray[4]}`}}
                                                onClick={() => setQuantity(quantity + 1)}
                                            >
                                                <IconPlus size={16} color={theme.colors.dark[2]} stroke={2} />
                                            </ActionIcon>
                                        }
                                        leftSectionWidth={36}
                                    />
                                </Group>
                                : null
                            }
                            

                            <Button
                                type='submit' fullWidth variant="filled"
                                size='lg' color={dataStore?.information?.backgroundColor || "#d84a2f"}
                                c={dataStore?.information?.textColor || "#fff"}
                                onClick={addItemToLocalStorage}
                            >إضافى الى السلة</Button>
                            {/* <FormOrder data={product} /> */}
                            <Box mt={30} c={"gray.7"} dangerouslySetInnerHTML={{__html: product?.description}} />
                        </Stack>
                    </Grid.Col>
                </Grid>

                
                {similarProducts?.length > 0
                    ? <>
                        <Group mt={50} mb={20}>
                            <Text fz={"18px"} c="gray.8">منتجات مشابهة</Text>
                        </Group>
                        <Carousel 
                            dir='ltr' loop dragFree align="start"
                            slideSize={{ base: '75%', xs: '50%', sm: '33.333333%', md: '25%', lg: '20%', xl: '20%' }}
                            slideGap={{ base: "xs", xs: "xs", sm: 'xs', md: 'sm', lg: 'sm', xl: 'md' }}
                            slidesToScroll={1}

                            plugins={[autoplay.current]}
                            onMouseEnter={autoplay.current.stop}
                            onMouseLeave={autoplay.current.reset}
                        >
                            {similarProducts?.map((item: any, index: number) => (
                                <Carousel.Slide key={index}>
                                    <ProductCard
                                        key={index}
                                        id={item._id}
                                        title={item?.name}
                                        image={item?.thumbnail}
                                        price={item?.price }
                                        priceAfterDiscount={item.priceAfterDiscount}
                                    />
                                </Carousel.Slide>
                            ))}
                        </Carousel>
                    </>
                    : null
                }
            </Container>
            <Footer/>
        </>
    );
}