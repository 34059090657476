import { Text, Group, ActionIcon, Box, Stack, Title, NumberInput, useMantineTheme } from '@mantine/core';
import classes from './../../styles/ProductCard.module.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IconMinus, IconPlus, IconTrash } from '@tabler/icons-react';


interface Props {
    index: number;
    title: string;
    image: string;
    price: number;
    priceAfterDiscount: number;
    quantity: number;
    hasOffer: boolean;
    deleteItemFromLocalStorage: (index: number) => void
    changeQuantityItemFromLocalStorage: (index: number, val: number) => void
}


export function CartProductCard({ index, image, title, price, priceAfterDiscount, quantity, hasOffer, deleteItemFromLocalStorage, changeQuantityItemFromLocalStorage }: Props) {
    const theme = useMantineTheme();

    return (
        <Box className={classes.boxProductCart}>
            <Group justify='space-between' wrap='nowrap'>
                <Group justify='flex-start' gap={10}>
                    <LazyLoadImage
                        src={`${process.env.REACT_APP_API_URL_IMAGES}/${image}.webp`}
                        delayTime={0}
                        style={{height: 70, width: "auto"}}
                        placeholderSrc={`${process.env.REACT_APP_API_URL_THUMBNAILS}/${image}.webp`}
                    />
                    <Stack gap={0}>
                        <Title order={4}>{title}</Title>
                        <Text size='sm' fw={600}>
                            {price * quantity} دج
                        </Text>
                    </Stack>
                </Group>
                <Stack gap={5} align='flex-end'>
                    <ActionIcon 
                        size="sm" color="gray" variant="light"
                        onClick={() => deleteItemFromLocalStorage(index)}
                    >
                        <IconTrash size={16} stroke={1.5} color="red" />
                    </ActionIcon>
                    {hasOffer && hasOffer === true
                        ? <Text fz={"12px"} fw={'bold'} c="green">
                            لقد اخترت عرض
                        </Text>
                        : <NumberInput
                            min={1} size='xs'
                            value={quantity}
                            styles={{
                                input: {
                                    border: `1px solid ${theme.colors.gray[4]}`,
                                    color: theme.colors.dark[3],
                                    fontWeight: 600,
                                    paddingInlineStart: 30,
                                    paddingInlineEnd: 30,
                                    textAlign: 'center',
                                    width: 110
                                }
                            }}

                            onChange={(value: number | string) => {
                                if (typeof value === "number") {
                                    changeQuantityItemFromLocalStorage(index, value)
                                }
                            }}
                            rightSection ={
                                <ActionIcon
                                    variant="transparent" h={"100%"} w={30} radius={0} 
                                    style={{borderRight: `1px solid ${theme.colors.gray[4]}`}}
                                    onClick={() => changeQuantityItemFromLocalStorage(index, (quantity - 1))}
                                >
                                    <IconMinus size={16} color={theme.colors.dark[2]} stroke={2} />
                                </ActionIcon>
                            }
                            rightSectionWidth={30}
                            leftSection={
                                <ActionIcon 
                                    variant="transparent" h={"100%"} w={30} radius={0} 
                                    style={{borderLeft: `1px solid ${theme.colors.gray[4]}`}}
                                    onClick={() => changeQuantityItemFromLocalStorage(index, (quantity + 1))}
                                >
                                    <IconPlus size={16} color={theme.colors.dark[2]} stroke={2} />
                                </ActionIcon>
                            }
                            leftSectionWidth={30}
                        />
                    }
                </Stack>
            </Group>
        </Box>
    );
}